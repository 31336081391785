





















































import { Inject } from '@cds/common';
import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import { WechatImageTextService } from '@/services/wechat-imagetext-service';
import searchPerson from '@/components/groupManage/searchPerson.vue';
import { massSet } from '@/services/massage-service';
import { log10 } from 'core-js/core/number';
@Component({
  components: {
    searchPerson
  }
})
export default class creatGroup extends Vue {
  [x: string]: any;
  public PerdialogFormVisible: Boolean = false;
  public labelPosition: String = 'top';
  public selectDisabled: Boolean = true;
  public subShow: Boolean = true;
  public noNumshow: Boolean = false;
  public type: String = '';
  public title: String = '查询';
  public totalNum: number = 0;//总个数
  public noNum: number = 0;//无效个数
  public useNum: number = 0;//有效个数
  public rowList: any = {};
  public form: any = {
    channelId: '',
    userListStr: ''
  };
  public rules: any = {
    channelId: [
      { required: true, message: '请选择专栏', trigger: 'change' }
    ],
    groupSetName: [
      { required: true, message: '请输入名称', trigger: 'change' }
    ],
    userListStr: [
      { required: true, message: '请输入用户邮箱', trigger: 'blur' }
    ],
    description: [
      { required: true, message: '请输入描述', trigger: ['change'] }
    ],

  }
  public queryParams: any = { //查询参数
    pages: 1,
    size: 5,
    current: 1,
    total: 0
  };
  private tableData: any[] = [];
  private applications2: any[] = [];
  public querySend: any = {
    channelId: ''
  }
  @Inject(WechatImageTextService) private wechatImageTextService!: WechatImageTextService;
  @Inject(massSet) private massSet!: massSet;
  public created(): void {
    this.applicationsList()
  }

  //获取专栏
  public async applicationsList(): Promise<void> {
    var Arr_ =[];
    const res = await this.wechatImageTextService.applicationsList();
    this.applications2 = res;
    this.type = (this.$route.query.type as string) || '';
    if (this.type == '1') {//修改
      this.rowList = this.$route.query.rowList;
      console.log(' this.rowLis', this.rowList)
      this.form = this.rowList;
      Arr_ = this.form.userListStr.split(',')
      this.totalNum = Arr_.length;
      this.querySend.channelId = this.form.channelId;
      this.selectDisabled = true;
    } else {
      this.form.channelId = this.applications2[0].id;
      this.querySend.channelId = this.applications2[0].id;
      this.selectDisabled = false;
    }
  }
  //保存
  public submitForm(formName: any): void {
    const addRef = (this.$refs.form as any)
    addRef.validate(async (valid: any) => {
      if (valid) {
        // this.form.userListStr =   this.form.userList;
        // this.form.userList =  '';
        if (this.type != '1') {
          let res: any = await this.massSet.addGroupSet(this.form);
          this.$message({
            message: '添加成功',
            type: 'success'
          });
          this.$router.go(-1);
        } else {
          let res: any = await this.massSet.editGroupSet(this.form);
          this.$message({
            message: '修改成功',
            type: 'success'
          });
          this.$router.go(-1);
        }
      } else {
        return false;
      }
    });
  }
  //返回
  public cancel() {
    this.$router.go(-1);
  }
  // 点击所属专栏
  public async creatApplacation(row: any): Promise<void> {
    this.querySend.channelId = row;
  }

  // 打开人员搜索弹窗
  public openSearch() {
    if (this.form.channelId == '' || this.form.channelId == undefined) {
      this.$message({
        message: '请先选择所属专栏',
        type: 'error'
      });
    } else {
      this.PerdialogFormVisible = true;
    }

  }
  //得到子组件传过来的值
  @Emit('close')
  public close(val: any): any {
    var strLength = [], Arr_ = [];
    if (this.form.userListStr == '') {
      this.form.userListStr += val.toString();

    } else {
      this.form.userListStr = this.form.userListStr + ',' + val.toString();
    }
    strLength = this.form.userListStr.split(',');
    Arr_ = Array.from(new Set(strLength));
    this.form.userListStr = Arr_.toString();
    this.totalNum = Arr_.length;
    if (this.totalNum > 0) {
      this.subShow = false;
    }
    this.PerdialogFormVisible = false;
  }
  // 键入textarea
  public userListStrWrite(e: any) {
    var strLength = [], Arr_ = [];
    this.noNumshow = false;
    if (this.form.userListStr == '' || this.form.userListStr == null || this.form.userListStr == undefined) {
      this.totalNum = 0;

    } else {
      strLength = this.form.userListStr.split(',');
      Arr_ = Array.from(new Set(strLength));
      this.form.userListStr = Arr_.toString();
      this.totalNum = Arr_.length;
    }
    if (this.totalNum > 0) {
      this.subShow = false;
    }
  }
  // 检测用户
  public async checkPerson(): Promise<void> {
    var data_ = {};
    data_ = {
      "usersStr": this.form.userListStr,
    }
    let res: any = await this.massSet.persnSearch(data_);
    if (res) {
      this.noNum = res.unFinds.length;
      this.useNum = res.empIds.length + res.emails.length;
      this.totalNum = res.unFinds.length + res.empIds.length + res.emails.length;
      this.noNumshow = true;
      this.form.userListStr = res.usersStr;
      if (res.unFinds.length > 0) {
        this.subShow = false;
        this.$alert('用户：' + res.unFinds.toString() + ' 不存在', '错误用户', {
          confirmButtonText: '确定',
          callback: action => {

          }
        });
      } else {
        this.$message({
          type: 'info',
          message: '用户检测完成'
        });
        this.subShow = true;
      }
    }

  }


}
